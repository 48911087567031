var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":((_vm.editMode ? 'Edit' : 'Add') + " Trainer"),"no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false:null,"formatter":_vm.capitalize},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Skill","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.skillItems,"state":errors.length > 0 ? false:null,"filterable":false},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No HP","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"No HP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.options.phone},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Provinsi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.provinceItems,"state":errors.length > 0 || _vm.submitErrors.province_id ? false:null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.provinceId),callback:function ($$v) {_vm.provinceId=$$v},expression:"provinceId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kota","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.regencyItems,"disabled":!_vm.provinceId,"state":errors.length > 0 || _vm.submitErrors.regency_id ? false:null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.regencyId),callback:function ($$v) {_vm.regencyId=$$v},expression:"regencyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kecamatan","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.districtItems,"disabled":!_vm.regencyId,"state":errors.length > 0 || _vm.submitErrors.district_id ? false:null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.districtId),callback:function ($$v) {_vm.districtId=$$v},expression:"districtId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Detail Alamat","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Detail Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Bank","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama Bank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.bankName),callback:function ($$v) {_vm.bankName=$$v},expression:"bankName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No Rekening","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"No Rekening","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.bankAccountNumber),callback:function ($$v) {_vm.bankAccountNumber=$$v},expression:"bankAccountNumber"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Pemilik Bank","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik Bank","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"formatter":_vm.capitalize,"state":errors.length > 0 ? false:null},model:{value:(_vm.bankAccountName),callback:function ($$v) {_vm.bankAccountName=$$v},expression:"bankAccountName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"NPWP","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"NPWP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.options.npwp},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"CV","label-cols-md":"4"}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"CV"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.cvInitialFile ?
                          _vm.cvInitialFile.split('/').pop()
                          : "Pilih atau drop file disini...","drop-placeholder":"Drop file disini..."},model:{value:(_vm.cvFile),callback:function ($$v) {_vm.cvFile=$$v},expression:"cvFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.cvInitialFile)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lihat File'),expression:"'Lihat File'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"gradient-secondary","target":"_blank","href":_vm.cvFile ? _vm.fileUrl(_vm.cvFile) : _vm.cvInitialFile}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}})],1)],1):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }